export const AI_FEATURE = {
  has_ai_summarisation: {
    name: "Summarisation",
  },
  has_ai_keyword_suggestion: {
    name: "Keyword Suggestion",
  },
  has_ai_general_assistant: {
    name: "General Assistant",
  },
  has_ai_boolean_creator: {
    name: "Boolean Creator",
  },
} as const;

export const AI_PROVIDER = {
  google_vertex_ai: {
    name: "Google Vertex AI",
  },
} as const;

export const AI_PROVIDER_MODEL = {
  "gemini-1.5-pro-002": {
    name: "Gemini 1.5 Pro 002",
    provider: "google_vertex_ai",
  },
} as const;
